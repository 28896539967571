import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class NewStockCountDetailProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-count-details', query)
  }

  getDetailById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/stock-count-details/${id}`)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/stock-count-details', payload)
  }

  updateStatusState (id, state) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-count-details/${id}/${state}`)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/stock-count-details/${id}`)
  }
}

export default NewStockCountDetailProvider
