import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class NewStockCountProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/stock-count', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/stock-count/${id}`)
  }

  delete (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/stock-count?id=${id}`)
  }

  updateStatusCompleted (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-count/${id}/completed`)
  }

  updateStatusState (id, state) {
    this.setHeader(getAuthToken())
    return this.patch(`/stock-count/${id}/${state}`)
  }
}

export default NewStockCountProvider
