<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3 pa-6">
    <v-row>
      <v-col cols="8">
        <h2>นับของ - {{ documentNo }}</h2>
      </v-col>
      <v-col
        v-if="isPending"
        cols="4"
        class="d-flex justify-end">
        <v-btn
          ountlined
          class="mr-3"
          :disabled="!formData.warehouse"
          @click="importDialog = true">
          <v-icon color="green">
            mdi-microsoft-excel
          </v-icon>
          Import Csv
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!formData.warehouse"
          @click="openModalConfirm()">
          SAVE
        </v-btn>
      </v-col>
      <v-col
        v-if="formData.code"
        cols="12">
        <p class="text-body">
          <span class="text-bold">
            หมายเลขใบนับของ :
          </span>
          <span>
            {{ formData.code }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="d-flex justify-end">
        <WarehouseSelect
          v-model="formData.warehouse"
          :items="filteredWarehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          return-object
          outlined
          :disabled="!isPending" />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex justify-end">
        <v-text-field
          v-model="formData.note"
          label="Location / Note"
          outlined
          hide-details
          dense
          :disabled="!isPending"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex justify-end">
        <v-text-field
          v-model="scanField"
          append-icon="mdi-qrcode-scan"
          placeholder="นำ Cursor มาไว้ตรงนี้ก่อนแสกน"
          outlined
          hide-details
          dense
          :disabled="!formData.warehouse || !isPending"
          @keydown.enter="onScanQr"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tabs">
          <v-tab
            href="#tab-list"
            class="primary--text">
            <v-icon class="mr-1">
              mdi-view-list
            </v-icon>
            รายการ
          </v-tab>

          <v-tab
            href="#tab-history"
            class="primary--text">
            <v-icon class="mr-1">
              mdi-layers
            </v-icon>
            ประวัติ
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tabs"
          class="mt-4">
          <v-tab-item value="tab-list">
            <v-card flat>
              <v-simple-table
                v-for="(item, key) in countingSummary"
                :key="`${item.name}-${key}`"
                class="mt-4 elevation-2">
                <template #default>
                  <tbody :style="`color: ${item.docExist ? '#000000' : '#0f3fff'}`">
                    <tr>
                      <td
                        width="85%"
                        colspan="2"
                        class="font-weight-bold">
                        {{ item.name }}
                      </td>
                      <td
                        class="text-right font-weight-bold"
                        width="15%">
                        {{ item.quantity | showNumberFormat() }}
                      </td>
                    </tr>
                    <tr
                      v-for="(itemSku, index) in item.items"
                      :key="index">
                      <td
                        class="pl-10"
                        width="55%">
                        {{ itemSku.code }}
                      </td>
                      <td width="30%">
                        {{ itemSku.color }} / {{ itemSku.size }}
                      </td>
                      <td
                        width="15%"
                        class="text-right">
                        {{ itemSku.quantity | showNumberFormat() }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-history">
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th
                      width="5%"
                      class="text-left">
                      No.
                    </th>
                    <th
                      width="85%"
                      class="text-left">
                      รายการ
                    </th>
                    <th
                      width="10%"
                      class="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in scanLogs"
                    :key="index"
                    :style="`color: ${item.docExist ? '#000000' : '#0f3fff'}`">
                    <td width="5%">
                      {{ index + 1 }}
                    </td>
                    <td width="85%">
                      {{ item.code }}
                    </td>
                    <td
                      width="10%"
                      class="text-center">
                      <v-btn
                        icon
                        @click="deleteScanLog(index)">
                        <v-icon color="red">
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog
      v-model="importDialog"
      transition="dialog-bottom-transition"
      max-width="700">
      <template #default="dialog">
        <v-card>
          <v-toolbar
            color="primary"
            dark>
            Import CSV
          </v-toolbar>
          <v-card-text>
            <div
              v-if="csvFile"
              class="text-body pa-12 text-center">
              {{ fileImportName }}
              <v-btn icon>
                <v-icon color="red">
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </div>
            <div
              v-else
              class="text-body pa-12 text-center">
              <img
                style="margin: auto;"
                width="70%"
                :src="correctData"
                alt="">
              <p>ภาพที่ 1.1 แสดงตัวอย่างไฟล์ที่สามารถ Upload ได้</p>
              <img
                style="margin: auto;"
                width="200"
                :src="correctExt"
                alt="">
              <p class="mt-2">
                ภาพที่ 1.2 แสดงตัวอย่างไฟล์ที่นามสกุล csv
              </p>
              <v-btn
                class="mt-4"
                dark
                color="green"
                @click="triggerFileSelect">
                Select CSV
              </v-btn>
              <input
                ref="fileInput"
                accept=".csv"
                type="file"
                style="display: none"
                @change="handleFileSelect" />
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.value = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as XLSX from 'xlsx'
import NewStockCountProvider from '@/resources/NewStockCountProvider'
import NewStockCountDetailProvider from '@/resources/NewStockCountDetailProvider'
import ProductProvider from '@/resources/ProductProvider'
import CorrectDataImage from '@/assets/image/correct-data.png'
import CorrectExtImage from '@/assets/image/correct-ext.png'
import WarehouseSelect from '@/components/WarehouseSelect.vue'

const newStockCountService = new NewStockCountProvider()
const newStockCountDetailService = new NewStockCountDetailProvider()
const ProductService = new ProductProvider()

export default {
  components: {
    WarehouseSelect
  },
  data () {
    return {
      loading: false,
      formData: {
        id: null,
        code: '',
        stockCount: null,
        stockCountCode: '',
        warehouse: null,
        items: [],
        stateHistories: [],
        note: '',
        state: 'pending'
      },
      stockCount: null,
      query: {
        status: '',
        page: 1,
        limit: 10,
        search: ''
      },
      pageCount: 0,
      importDialog: false,
      correctData: CorrectDataImage,
      correctExt: CorrectExtImage,
      tabs: null,
      scanLogs: [],
      scanField: 'https://www.gentlewomanonline.com/GW.38.1.99.16297.4.2',
      csvFile: null,
      products: []
    }
  },
  computed: {
    ...mapGetters({
      storeSetting: 'Store/storeSetting',
      mapWarehouse: 'Store/mapWarehouse',
      modal: 'Components/modal'
    }),
    documentNo () {
      return this.stockCount?.code || ''
    },
    detailId () {
      return this.$route.params?.detailId || ''
    },
    filteredWarehouses () {
      const stockCountWarehouses = this.stockCount?.warehouses || []
      const stockCountWarehouseIds = stockCountWarehouses.map((v) => v.id)

      return this.mapWarehouse.filter((v) => stockCountWarehouseIds.includes(v.id))
    },
    isPending () {
      return this.formData.state === 'pending'
    },
    countingSummary () {
      const groupByProduct = this.formData.items.reduce((acc, product) => {
        const { productId, name, code, color, size, quantity, docExist } = product

        if (!quantity) {
          return acc
        }

        if (!acc[productId]) {
          acc[productId] = {
            name,
            quantity: quantity || 0,
            items: [],
            docExist
          }
        } else {
          acc[productId].docExist = acc[productId].docExist || docExist
          acc[productId].quantity += quantity || 0
        }

        acc[productId].items.push({
          code,
          color,
          size,
          quantity: quantity || 0,
          docExist
        })

        return acc
      }, {})

      return Object.values(groupByProduct).filter((v) => v.quantity > 0)
    },
    fileImportName () {
      return this.csvFile ? this.csvFile.name : 'คุณยังไม่ได้เลือกไฟล์'
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getStockCountLogsList()
      },
      deep: true
    },
    'query.status': {
      handler () {
        this.getStockCountLogsList()
      },
      deep: true
    },
    'query.search': {
      handler () {
        clearTimeout(this.delayVendorSearch)
        this.delayVendorSearch = setTimeout(() => {
          this.getStockCountLogsList()
        }, 300)
      },
      deep: true
    },
    'formData.warehouse': {
      handler (val) {
        if (!this.detailId) {
          this.formData.items = this.stockCount.items
            .filter((v) => v.warehouse.id === val.id)
            .map((v) => {
              const item = this.formData.items.find((i) => i.code === v.code)

              return {
              ...v,
                quantity: item?.quantity || 0
              }
            })
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getStockCount()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    async getStockCount () {
      try {
        this.setLoading({ active: true })

        const { data } = await newStockCountService.getById(this.$route.params.stockCountId)
        const warehouseId = this.$route.query.warehouse || null

        this.stockCount = { ...data }

        if (this.detailId) {
          const { data: detailData } = await newStockCountDetailService.getDetailById(this.detailId)

          this.formData = { ...detailData }
        } else {
          this.formData.stockCount = data.id
          this.formData.stockCountCode = data.code

          if (warehouseId) {
            this.formData.warehouse = data.warehouses.find((v) => v.id === Number(warehouseId))
            this.formData.items = data.items
              .filter((v) => v.warehouse.id === Number(warehouseId))
              .map((v) => ({
                ...v,
                quantity: 0
              }))
          }
        }
      } catch (error) {
        console.error('getStockCount', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getProductByCode (code) {
      try {
        this.setLoading({ active: true })

        const localProduct = this.products.find((v) => v.skuModels.includes(code))

        if (localProduct) {
          return {
            product: localProduct,
            sku: localProduct.skus.find((v) => v.code === code)
          }
        }

        const { data } = await ProductService.getManyProductByCode({ codes: [code] })

        if (!data.length) {
          throw Error('Product not found.')
        }

        this.products.push(data[0])

        return {
          product: data[0],
          sku: data[0].skus.find((v) => v.code === code)
        }
      } catch (error) {
        console.error('getProductByCode', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })

        return {
          product: null,
          sku: null
        }
      } finally {
        this.setLoading({ active: false })
      }
    },
    async onSubmit () {
      try {
        this.setLoading({ active: true })

        await newStockCountDetailService.createItem(this.formData)

        this.$router.push({
          name: 'StockCountDetail',
          params: { stockCountId: this.$route.params.stockCountId }
        })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    openModalConfirm () {
      this.setModal({
        value: true,
        title: 'ยืนยันการบันทึก',
        message: 'ต้องการบันทึกใบนับสินค้านี้ หรือไม่',
        confirmText: 'ยืนยัน',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'ยกเลิก',
        onConfirm: () => this.onSubmit()
      })
    },
    async countProduct (code) {
      const skuProductIndex = this.formData.items.findIndex((skuProduct) => skuProduct.code === code)

      if (skuProductIndex !== -1) {
        this.formData.items[skuProductIndex].quantity += 1
        this.scanLogs.push({
          code,
          docExist: this.formData.items[skuProductIndex].docExist
        })
      } else {
        const { product, sku } = await this.getProductByCode(code)

        if (!sku) {
          this.setSnackbar({
            value: true,
            message: `ไม่พบสินค้าหมายเลข ${code}`,
            type: 'error'
          })
        } else {
          this.formData.items.push({
            skuId: sku?.id || null,
            productId: product.id,
            aliasId: product.aliasId,
            model: product.model,
            code,
            color: sku?.color?.name || null,
            size: sku?.size?.name || null,
            name: product.name,
            photoUrl: product.photoUrls[0] || '',
            price: sku?.price || product.price,
            physicalQuantity: 0,
            quantity: 1,
            variant: sku?.variant || 'SKU',
            docExist: false,
            brand: product.brand
          })

          this.scanLogs.push({
            code,
            docExist: false
          })
        }
      }
    },
    onScanQr () {
      const codes = this.scanField.trim().split(`${this.storeSetting.webUrl}/`)
      const code = codes[codes.length - 1]

      if (code === '') return

      this.countProduct(code)
      this.scanField = ''
    },
    deleteScanLog (index) {
      const skuProductIndex = this.formData.items.findIndex((skuProduct) => skuProduct.code === this.scanLogs[index])
      this.formData.items[skuProductIndex].quantity -= 1
      this.scanLogs.splice(index, 1)
    },
    triggerFileSelect () {
      this.$refs.fileInput.click()
    },
    handleFileSelect (event) {
      const file = event.target.files[0]
      if (file) {
        this.csvFile = file
        this.parseCSVAndCount(file)
      }
    },
    async parseCSVAndCount (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        jsonData.flat().forEach((code) => {
          const codes = code.trim().split(`${this.storeSetting.webUrl}/`)

          this.countProduct(codes[codes.length - 1])
        })
      }
      reader.readAsArrayBuffer(file)
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.table-detail {
    width: 100%;
}
.table-detail tr {
    line-height: 35px;
}
</style>
